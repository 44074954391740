import './App.css';
import Services from './pages/Services';

function App({language}) {
  return (
    <>
    <Services language={language} />
    </>
  );
}

export default App;
