import React, { useEffect, useState } from "react";
import { Router, useSearchParams, useLocation } from "react-router-dom";
import ApplyHousing from "../sections/ApplyHousing/ApplyHousing";
import axios from "axios";

const Services = ({ language }) => {
  const [data, setData] = useState(null);
  const [isLoader, setisLoader] = useState(false);
  const [PersonaId, setPersonaId] = useState(null);

  const [PersonaList, setPersonaList] = useState(null);

  const [selectMain, setselectMain] = useState(null);
  const [selectSub, setselectSub] = useState(null);
  const [selectService, setselectService] = useState(null);

  const [SubList, setSubList] = useState([]);

  const [MainList, setMainList] = useState([]);
  const [MainListChild, setMainListChild] = useState([]);

  const searchParams = new URLSearchParams(document.location.search);

  const serviceID = searchParams.get("service");
  const personaIDUrl = searchParams.get("Persona");

  useEffect(() => {
    var requestOptions = {
      method: "GET",
    };
    fetch(
      "https://dscmbrhe.dcx360.com/api/v1/DSC/MainServicesTree",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (!!result && !!result.Data) {
          setMainList(result.Data);
          setselectMain(result.Data[0].ID);
          setMainListChild(
            result.Data[0].Children.length < 1
              ? [result.Data[0]]
              : result.Data[0].Children
          );
          setselectSub(
            result.Data[0].Children.length < 1
              ? result.Data[0].ID
              : result.Data[0].Children[0].ID
          );
        }
      })
      .catch((error) => console.log("error", error));
  }, []);

  useEffect(() => {
    setData(null);
    setisLoader(true);
    setPersonaList(null);
    var personaString = "";
    if (!!PersonaId && PersonaId !== "") {
      personaString = "&Persona=" + PersonaId;
    } else if (!!personaIDUrl && personaIDUrl !== "") {
      personaString = "&Persona=" + personaIDUrl;
    }
    const fetchData = async () => {
      var requestOptions = {
        method: "GET",
      };

      fetch(
        "https://dscmbrhe.dcx360.com/api/v1/DSC/ServiceDetails?ID=" +
          (selectService || 0),
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setData(result);
          if (
            !!result &&
            !!result.ServiceInfo &&
            !!result.ServiceInfo.PersonaList
          ) {
            setPersonaList(result.ServiceInfo.PersonaList);
          }
          setisLoader(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    if (!!selectService) {
      fetchData();
      setisLoader(true);
    } else {
      setisLoader(false);
    }
  }, [selectService]);

  useEffect(() => {
    setisLoader(true);
    setSubList(null);
    var requestOptions = {
      method: "GET",
    };
    if (!!selectSub) {
      fetch(
        "https://dscmbrhe.dcx360.com/api/v1/DSC/ServiceList?Main=" +
          selectSub +
          "&Page=1&Count=100",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setData(result);
          if (!!result && !!result.Data) {
            setSubList(result.Data);
          }
          setisLoader(false);
        })
        .catch((error) => {
          console.log("error", error);
          setisLoader(false);
        });
    } else {
      setisLoader(false);
    }
  }, [selectSub]);

  return (
    <>
          <div class="row">
            <div class="x-quick-main-menu col-md-3">
              {!!MainList &&
                MainList.map((item) => {
                  return (
                    <a
                      class="x-quick-main-menu-item"
                      tabIndex={1}
                      onClick={() => {
                        setselectMain(item.ID);
                        setMainListChild(
                          item.Children.length < 1 ? [item] : item.Children
                        );
                        setselectSub(
                          item.Children.length < 1
                            ? item.ID
                            : item.Children[0].ID
                        );
                        console.log(item.Children);
                      }}
                      onKeyUp={(event)=>{
                        if (event.keyCode === 13 || event.which === 13) {
                          setselectMain(item.ID);
                        setMainListChild(
                          item.Children.length < 1 ? [item] : item.Children
                        );
                        setselectSub(
                          item.Children.length < 1
                            ? item.ID
                            : item.Children[0].ID
                        );
                        console.log(item.Children);
                        }
                      }}
                    >
                      <div class="x-main-menu-svg-container">
                        <img
                          src={`/Theme/images/new-images/serviceicon-${item.ID}.svg`}
                          alt="housing"
                        />
                      </div>
                      <div class="x-quick-main-menu-link">
                        {language == "ar" ? item.NameAr : item.NameEn}
                      </div>
                    </a>
                  );
                })}
            </div>

            <div class="x-quick-sub-menu col-md-9">
              <div class="x-quick-sub-menu-wrapper x-quick-sub-menu-housing-service x-active">
                {!!MainList &&
                  MainList.map((itemMW) => {
                    return (
                      <>
                        <a
                          class="x-quick-main-menu-item"
                          tabIndex={1}
                          onClick={() => {
                            setselectMain(itemMW.ID);
                            setMainListChild(
                              itemMW.Children.length < 1
                                ? [itemMW]
                                : itemMW.Children
                            );
                            setselectSub(
                              itemMW.Children.length < 1
                                ? itemMW.ID
                                : itemMW.Children[0].ID
                            );
                            console.log(itemMW.Children);
                          }}
                          onKeyUp={(event)=>{
                            if (event.keyCode === 13 || event.which === 13) {
                              setselectMain(itemMW.ID);
                            setMainListChild(
                              itemMW.Children.length < 1
                                ? [itemMW]
                                : itemMW.Children
                            );
                            setselectSub(
                              itemMW.Children.length < 1
                                ? itemMW.ID
                                : itemMW.Children[0].ID
                            );
                            console.log(itemMW.Children);
                            }
                          }}
                        >
                          <div class="x-main-menu-svg-container">
                            <img
                              src={`/Theme/images/new-images/serviceicon-${itemMW.ID}.svg`}
                              alt={language == "ar" ? itemMW.NameAr : itemMW.NameEn}
                            />
                          </div>
                          <div class="x-quick-main-menu-link">
                            {language == "ar" ? itemMW.NameAr : itemMW.NameEn}
                          </div>
                        </a>
                        {!!itemMW && itemMW.ID == selectMain && (
                          <div class="midNav">
                            <ul>
                              {!!MainListChild &&
                                MainListChild.map((item, index) => {
                                  if (!!item) {
                                    return (
                                      <li>
                                        <a
                                          class="Main_Title"
                                          tabIndex={1}
                                          onClick={() => {
                                            setselectSub(item.ID);
                                          }}
                                          onKeyUp={(event)=>{
                                            if (event.keyCode === 13 || event.which === 13) {
                                              setselectSub(item.ID);
                                            }
                                          }}
                                        >
                                          {language == "ar"
                                            ? item.NameAr
                                            : item.NameEn}
                                        </a>
                                        {selectSub == item.ID && (
                                          <ul>
                                            {!!SubList &&
                                              SubList.map((itemB, indexB) => {
                                                return (
                                                  <li>
                                                    <a
                                                      class="midSubSubL"
                                                      tabIndex={1}
                                                      onClick={() => {
                                                        setselectService(
                                                          itemB.ID
                                                        );
                                                      }}
                                                      onKeyUp={(event)=>{
                                                        if (event.keyCode === 13 || event.which === 13) {
                                                          setselectService(
                                                            itemB.ID
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      {language == "ar"
                                                        ? itemB.NameAR
                                                        : itemB.NameEn}
                                                    </a>
                                                    {!!data &&
                                                      !!data.ServiceInfo &&
                                                      selectService ==
                                                        itemB.ID && (
                                                        <ul
                                                          class="ulSubSub"
                                                          style={{
                                                            display: "block",
                                                          }}
                                                        >
                                                          <li>
                                                            <div className="closeThisServiceBox"></div>
                                                            <div class="service_card">
                                                              <h2 className="x-desc-title">
                                                                {language ==
                                                                "ar"
                                                                  ? data
                                                                      .BasicInfo
                                                                      .TitleAR
                                                                  : data
                                                                      .BasicInfo
                                                                      .TitleEN}
                                                              </h2>
                                                              <p>
                                                                {language ==
                                                                "ar"
                                                                  ? data
                                                                      .BasicInfo
                                                                      .DescriptionAR
                                                                  : data
                                                                      .BasicInfo
                                                                      .DescriptionEN}
                                                              </p>
                                                              {!!PersonaList && (
                                                                <ApplyHousing
                                                                  setPersonaId={
                                                                    setPersonaId
                                                                  }
                                                                  PersonaList={
                                                                    PersonaList
                                                                  }
                                                                  selectService={
                                                                    selectService
                                                                  }
                                                                  data={data}
                                                                  language={
                                                                    language
                                                                  }
                                                                />
                                                              )}
                                                              {!!PersonaList ==
                                                                false && (
                                                                <div
                                                                  className=" services_btn"
                                                                  style={{
                                                                    padding:
                                                                      "10px 0px",
                                                                    "text-align":
                                                                      "left",
                                                                  }}
                                                                >
                                                                  {!!data
                                                                    .AdvancedInfo
                                                                    .ApplyLink &&
                                                                    data
                                                                      .AdvancedInfo
                                                                      .ApplyLink !==
                                                                      "" && (
                                                                      <a
                                                                        className="apply_btn x-btnA"
                                                                        tabIndex={1}
                                                                        href={
                                                                          language ==
                                                                          "ar"
                                                                            ? data
                                                                                .AdvancedInfo
                                                                                .ApplyLinkAr
                                                                            : data
                                                                                .AdvancedInfo
                                                                                .ApplyLink
                                                                        }
                                                                      >
                                                                        {language ==
                                                                        "ar"
                                                                          ? "التقديم على الخدمة"
                                                                          : "Apply For Service"}
                                                                      </a>
                                                                    )}
                                                                  <a
                                                                  tabIndex={1}
                                                                    onClick={() => {
                                                                      window.location.replace(
                                                                        window
                                                                          .location
                                                                          .origin +
                                                                          "/" +
                                                                          language +
                                                                          "/default.aspx?PageId=526&service=" +
                                                                          selectService
                                                                      );
                                                                    }}
                                                                    onKeyUp={(event)=>{
                                                                      if (event.keyCode === 13 || event.which === 13) {
                                                                        window.location.replace(
                                                                          window
                                                                            .location
                                                                            .origin +
                                                                            "/" +
                                                                            language +
                                                                            "/default.aspx?PageId=526&service=" +
                                                                            selectService
                                                                        );
                                                                      }
                                                                    }}
                                                                    className={
                                                                      "x-btnB"
                                                                    }
                                                                  >
                                                                    {language ==
                                                                    "ar"
                                                                      ? "بطاقة الخدمة"
                                                                      : "Service Card"}
                                                                  </a>
                                                                </div>
                                                              )}
                                                            </div>
                                                          </li>
                                                        </ul>
                                                      )}
                                                  </li>
                                                );
                                              })}
                                          </ul>
                                        )}
                                      </li>
                                    );
                                  }
                                })}
                            </ul>
                          </div>
                        )}
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
      {!!isLoader && (
        <div class="dc-loaderwrap-outer">
          <div class="dc-loaderwrap">
            <span class="dc-loader"></span>
          </div>
        </div>
      )}
    </>
  );
};

export default Services;
