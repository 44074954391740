import React, { useState, useEffect } from "react";
import Dropdown from "../../components/Dropdown/Dropdown";
import "./applyHousing.css";

const ApplyHousing = ({
  PersonaList,
  setPersonaId,
  selectService,
  data,
  language,
}) => {
  const [PersonaList1, setPersonaList1] = useState(null);
  const [PersonaList2, setPersonaList2] = useState(null);

  const [ActiveValue1, setActiveValue1] = useState(null);
  const [ActiveValue2, setActiveValue2] = useState(null);

  useEffect(() => {
    var Array1 = [];
    if (!!PersonaList && PersonaList.length > 0) {
      PersonaList.map((item) => {
        Array1.push({
          id: item.id,
          label: language == "ar" ? item.nameAr : item.name,
          children: item.children,
        });
      });
      setPersonaList1(Array1);
    }
  }, [PersonaList]);

  useEffect(() => {
    setPersonaList2(null);
    if (!!ActiveValue1) {
      if (!!PersonaList && PersonaList.length > 0) {
        PersonaList.map((itemx) => {
          if (
            itemx.id == ActiveValue1 &&
            !!itemx.children &&
            itemx.children.length > 0
          ) {
            var Array2 = [];
            itemx.children.map((item) => {
              Array2.push({
                id: item.id,
                label: language == "ar" ? item.nameAr : item.name,
              });
            });
            setPersonaList2(Array2);
          }
        });
      }
    }
  }, [ActiveValue1]);

  //   useEffect(() => {
  //     if (!!ActiveValue2) {
  //       setPersonaId(ActiveValue2);
  //     }
  //   }, [ActiveValue2]);

  const setPersona = (Persona) => {
    setPersonaId(Persona);
    window.location.replace(
      window.location.origin +
        "/" +
        language +
        "/default.aspx?PageId=526&service=" +
        selectService +
        "&Persona=" +
        Persona
    );
  };

  return (
    <>
      {!!PersonaList1 && (
        <div className="services-types">
          <p>
            {language == "ar"
              ? "حدد الجنس والفئة لتخصيص المعلومات."
              : "Tell us a bit more about yourself so we can show the details according to your preferences"}
          </p>
          {/* <p style={{"margin-bottom": "5px"}}>
            Choose your persona:
          </p> */}
          <div className="filter-services">
            {!!PersonaList1 && (
              <select
                name="Select1"
                id="Select1"
                onChange={(e) => {
                  setActiveValue1(e.target.value);
                  setActiveValue2(null);
                }}
                tabIndex={1}
                className="selectCustom"
              >
                <option value={0}>
                  {language == "ar"
                    ? data.ServiceInfo.PersonaLevel1AR
                    : "Select " + data.ServiceInfo.PersonaLevel1}
                </option>
                {PersonaList1.map((item, index) => {
                  return <option value={item.id}>{item.label}</option>;
                })}
              </select>
            )}
            {!!PersonaList2 && (
              <select
                name="Select1"
                id="Select1"
                onChange={(e) => {
                  setActiveValue2(e.target.value);
                }}
                tabIndex={1}
                className="selectCustom"
              >
                <option value={0} disabled selected>
                  {language == "ar"
                    ? data.ServiceInfo.PersonaLevel2AR
                    : "Select " + data.ServiceInfo.PersonaLevel2}
                </option>
                {PersonaList2.map((item, index) => {
                  return <option value={item.id}>{item.label}</option>;
                })}
              </select>
            )}
          </div>
        </div>
      )}

      <div
        className=" services_btn"
        style={{ padding: "10px 0px", "text-align": "left" }}
      >
        {!!data.AdvancedInfo.ApplyLink && data.AdvancedInfo.ApplyLink !== "" && (<a class="apply_btn x-btnA" tabIndex={1} href={language == "ar" ? data.AdvancedInfo.ApplyLinkAr : data.AdvancedInfo.ApplyLinkAr}>
          {language == "ar" ? "التقديم على الخدمة" : "Apply For Service"}
        </a>)}
        <a
          onClick={() => {
            setPersona(ActiveValue2 || null);
          }}
          onKeyUp={(event)=>{
            if (event.keyCode === 13 || event.which === 13) {
              setPersona(ActiveValue2 || null);
            }
          }}
          className={"x-btnB"}
          tabIndex={1}
        >
          {language == "ar" ? "بطاقة الخدمة" : "Service Card"}
        </a>
      </div>
    </>
  );
};

export default ApplyHousing;
